<template>
    <b-card>
        <b-row class="mb-2">
            <b-col class="text-right">
                <b-button @click="showModalParent('')"  variant="primary" v-if="hasPermission">Add parent</b-button>
                <b-modal id="parents-modal" ref="modal" :title="parentEntity.id > 0 ? 'Edit parent' : 'Add parent'" size="lg" centered no-close-on-backdrop no-close-on-esc>
                    <ValidationObserver v-slot="{ passes }">
                        <b-form @submit.prevent="passes(saveParents)" id="parents-form">
                            <b-row>
                                <b-col md="6" sm="12">
                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                        <b-form-group label="Code" :invalid-feedback="errors[0]">
                                            <b-form-input v-model="parentEntity.name" :state="errors[0] ? false : null" autocomplete="off"></b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="6" sm="12">
                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                        <b-form-group label="Crop family" :invalid-feedback="errors[0]" :state="errors[0] ? false : null">
                                            <v-select label="text" :options="parentFamily" @search:focus="getFamilyEntity" @search="getFamilyEntity" placeholder="Select family"
                                                      :reduce="cropFamilyId => cropFamilyId.value" v-model="parentEntity.cropFamilyId" :state="errors[0] ? false : null">
                                                <template #no-options>No results</template>
                                            </v-select>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="6" sm="12">
                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                        <b-form-group label="Gender" :clearable="true" :invalid-feedback="errors[0]" :state="errors[0] ? false : null">
                                                <v-select label="text" v-model="parentEntity.gender" :options="['Female', 'Male']" placeholder="Select gender"
                                                          :class="{'is-invalid': errors.length > 0}" ></v-select>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="6" sm="12">
                                    <ValidationProvider rules="required|number|min_value:0" v-slot="{ errors }">
                                        <b-form-group label="Germination NL%" :invalid-feedback="errors[0]">
                                            <b-form-input v-model="parentEntity.germinationPercent" :state="errors[0] ? false : null" autocomplete="off"></b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                        </b-form>
                    </ValidationObserver>
                    <template #modal-footer>
                        <div class="text-right">
                            <b-button @click="$bvModal.hide('parents-modal')" class="mr-2" variant="outline-secondary"> Cancel</b-button>
                            <b-button type="submit" form="parents-form" variant="primary"><font-awesome-icon icon="save"></font-awesome-icon> Save</b-button>
                        </div>
                    </template>
                </b-modal>
            </b-col>
        </b-row>

        <b-row class="mb-2">
            <b-col sm="5" md="6" class="my-1">
                <b-input-group size="sm" prepend="Show" append="records">
                    <b-form-select v-model="parents.itemsPerPage" id="perPageSelect" size="sm" :options="parents.pageOptions" style="max-width: 5em"></b-form-select>
                </b-input-group>
            </b-col>
        </b-row>

        <b-table :items="loadData" :fields="filteredFields" :filter="parents.filter" :current-page="parents.currentPage"
                 :per-page="parents.itemsPerPage" empty-text="No records" striped bordered hover small show-empty :responsive="true"
                 :sort-by="parents.sortBy" :sort-desc="parents.sortDesc" ref="parentalTable" class="text-left mb-0" @context-changed="tableContextChanged">

            <template #thead-top>
                <b-tr class="table-filters">
                    <b-th v-for="(field, index) in filteredFields" :key="field.key" :class="parents.filters[index] && parents.filters[index].value ? 'non-empty' : ''">
                        <b-row v-if="parents.filters[index]">
                            <b-col v-if="parents.filters[index].type === 'STRING'">
                                <b-input :placeholder="field.label" v-model="parents.filters[index].value" debounce="300"></b-input>
                            </b-col>
                        </b-row>
                    </b-th>
                </b-tr>
            </template>

            <template #cell(varieties)="row">
<!--                <template v-if="row.item.varieties && row.item.varieties.includes(',') === true">-->
                    <b-popover :target="'varieties-' + row.item.id" triggers="click blur" custom-class="wide-popover" placement="bottom">
                        <template #title>Varieties</template>
                        {{ row.item.varieties}}
                    </b-popover>
                    <p :id="'varieties-' + row.item.id"
                       style="white-space: nowrap; width: 100%; overflow: hidden; text-overflow: ellipsis;">
                        {{ row.item.varieties }}
                    </p>
<!--                </template>-->
<!--                <template v-else>-->
<!--                    <p>{{ row.item.varieties }}</p>-->
<!--                </template>-->
            </template>

            <template #cell(actions)="row" v-if="hasPermission">
                <b-button-group size="sm">
                    <b-button size="sm" @click="showModalParent(row.item)" v-b-tooltip.hover title="Edit" variant="outline-primary">
                        <font-awesome-icon icon="pencil-alt"></font-awesome-icon>
                    </b-button>
                    <b-button size="sm" @click="deleteParent(row.item.id)" v-b-tooltip.hover title="Delete" variant="outline-primary">
                        <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                    </b-button>
                </b-button-group>
            </template>

            <template #table-caption>
                <b-row class="no-gutters">
                    <b-col sm="5" md="6">
                        Show {{ start }} to {{ end }} of {{ parents.totalRows }} record{{parents.totalRows !== 1 ? 's' : ''}}
                    </b-col>
                    <b-col sm="7" md="6" class="my-1">
                        <b-pagination v-model="parents.currentPage" :total-rows="parents.totalRows" :per-page="parents.itemsPerPage" align="right" class="mb-0"></b-pagination>
                    </b-col>
                </b-row>
            </template>
        </b-table>
    </b-card>
</template>
<script>
import { extend } from 'vee-validate';
import { ParentsEntity } from '../../models/parents';
import { parentSaveUrl, parentListUrl, parentDeleteUrl, parentFamilySelectUrl } from '@routes';
import tableStateMixin from '../../mixins/tableState';
import { required, min_value } from 'vee-validate/dist/rules';
import Utilities from '@/js/utilities';
import $permission from '@/js/permission';

extend('required', {
    ...required,
    message: 'Field required'
});

extend('min_value', {
    ...min_value,
    message: 'The value must be greater than 0'
});

extend('number', value => {
    if (Utilities.isNumeric(value)) {
        return true;
    }
    return 'The value must be a number';
});
export default {
    mixins: [tableStateMixin],
    data() {
        return {
            parentEntity: ParentsEntity.create(),
            toastTitle: 'Parents',
            parents: {
                fields: [
                    { key: 'name', label: 'Code', sortable: true, class: 'text-center' },
                    { key: 'cropFamily', label: 'Crop family', sortable: true, thStyle: 'width: 12%', class: 'text-center' },
                    { key: 'gender', label: 'Gender', sortable: true, thStyle: 'width: 10%', class: 'text-center' },
                    { key: 'germinationPercent', label: 'Germination NL%', sortable: true, class: 'text-center' },
                    { key: 'varieties', label: 'Variety', sortable: false, class: 'text-center', thStyle: 'width: 20%' },
                    // { key: 'createdBy', label: 'Created by', sortable: true, class: 'text-center' },
                    // { key: 'updatedBy', label: 'Updated by', sortable: true, class: 'text-center' },
                    { key: 'actions', label: 'Actions', class: 'text-center' }
                ],
                fieldsList: [
                    { key: 'name', label: 'Code', sortable: true, class: 'text-center' },
                    { key: 'cropFamily', label: 'Crop family', sortable: true, thStyle: 'width: 12%', class: 'text-center' },
                    { key: 'gender', label: 'Gender', sortable: true, thStyle: 'width: 10%', class: 'text-center' },
                    { key: 'germinationPercent', label: 'Germination NL%', sortable: true, class: 'text-center' },
                    { key: 'varieties', label: 'Variety', sortable: false, class: 'text-center', thStyle: 'width: 20%' }
                ],
                filters: [
                    { column: 'name', type: 'STRING', value: '', format: '' },
                    { column: 'cropFamily', type: 'STRING', value: '', format: '' }
                ],
                filter: '',
                pageOptions: [10, 25, 50, 75, 100],
                totalRows: 0,
                currentPage: 1,
                itemsPerPage: 25,
                sortBy: 'name',
                sortDesc: false
            },
            breadcrumb: {
                title: 'Parents',
                path: []
            },
            parentFamily: [],
            hasPermission: $permission.check('ROLE_PARE_C')
        };
    },
    created() {
        this.$emit('update-breadcrumb', this.breadcrumb);
        this.restoreTableState(this.parents, this.tableState);
    },
    methods: {
        loadData(parents) {
            if (parents.sortBy !== '') {
                let data = {
                    filters: this.parents.filters.filter(filter => filter.value.trim() !== ''),
                    ...parents
                };
                data.currentPage = (data.currentPage - 1) * data.perPage;

                return this.axios.post(parentListUrl(), data).then(response => {
                    if (response.data.code === 'OK') {
                        this.parents.totalRows = response.data.totalRows;
                        return response.data.parentals;
                    } else {
                        return [];
                    }
                }).catch(error => {
                    if (error.response) {
                        this.$bvToast.toast(error.response.data.message, {
                            title: this.toastTitle,
                            variant: 'danger'
                        });
                    } else if (error.request) {
                        this.$bvToast.toast('An error occurred while executing the request', {
                            title: this.toastTitle,
                            variant: 'danger'
                        });
                    } else {
                        this.$bvToast.toast('An error occurred while configuring the request', {
                            title: this.toastTitle,
                            variant: 'danger'
                        });
                    }
                });
            }
        },
        saveParents() {
            this.axios.post(parentSaveUrl(this.parentEntity.id), this.parentEntity).then(response => {
                if (response.data.code === 'OK') {
                    this.$bvModal.hide('parents-modal');
                    this.$bvToast.toast(response.data.message, {
                        title: this.toastTitle,
                        variant: 'success'
                    });
                    this.$refs.parentalTable.refresh();
                } else {
                    this.$bvToast.toast(response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                    this.$refs.parentalTable.refresh();
                }
            }).catch(error => {
                if (error.response) {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else if (error.request) {
                    this.$bvToast.toast('An error occurred while executing the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else {
                    this.$bvToast.toast('An error occurred while configuring the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            });
        },
        deleteParent(parentId) {
            this.$swal({
                title: 'Are you sure to delete parent?',
                icon: 'warning',
                iconColor: '#bf0d0d',
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#bf0d0d',
                confirmButtonText: 'Yes'
            }).then(result => {
                if (result.value) {
                    this.axios.post(parentDeleteUrl(), {
                        id: parentId
                    }).then(response => {
                        if (response.data.code === 'OK') {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'success'
                            });
                            this.$refs.parentalTable.refresh();
                        } else {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }
                    }).catch(error => {
                        if (error.response) {
                            this.$bvToast.toast(error.response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        } else if (error.request) {
                            this.$bvToast.toast('An error occurred while executing the request', {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        } else {
                            this.$bvToast.toast('An error occurred while configuring the request', {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }
                    });
                }
            });
        },
        showModalParent(row) {
            if (row.id > 0) {
                this.parentEntity = JSON.parse(JSON.stringify(row));
                this.getFamilyEntity();
            } else {
                this.parentEntity = ParentsEntity.create();
            }

            this.$bvModal.show('parents-modal');
        },
        getFamilyEntity() {
            this.axios.get(parentFamilySelectUrl()).then(response => {
                this.parentFamily = response.data.families;
            }).catch(error => {
                if (error.response) {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else if (error.request) {
                    this.$bvToast.toast('An error occurred while executing the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else {
                    this.$bvToast.toast('An error occurred while configuring the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            });
        },
        tableContextChanged(context) {
            this.parents.sortBy = context.sortBy;
            this.parents.sortDesc = context.sortDesc;
            this.saveTableState(this.$route.name, this.parents);
        }
    },
    watch: {
        'parents.filters': {
            handler() {
                this.$refs.parentalTable.refresh();
                this.saveTableState(this.$route.name, this.parents);
            },
            deep: true
        }
    },
    computed: {
        start() {
            return this.parents.totalRows === 0 ? 0 : (this.parents.currentPage - 1) * this.parents.itemsPerPage + 1;
        },
        end() {
            let offset = (this.parents.currentPage - 1) * this.parents.itemsPerPage;

            return (this.parents.totalRows - offset) < this.parents.itemsPerPage ? this.parents.totalRows : offset + this.parents.itemsPerPage;
        },
        filteredFields() {
            if (this.hasPermission) {
                return this.parents.fields;
            } else {
                return this.parents.fieldsList;
            }
        }
    }
};
</script>