import { v4 as uuidv4 } from 'uuid';

export class ParentsEntity {
    constructor(id, name, gender, cropFamilyId, germinationPercent,  createdAt, updatedAt, createdById, updatedById) {

        this.id = id;
        this.name = name;
        this.gender = gender;
        this.cropFamilyId = cropFamilyId;
        this.germinationPercent = germinationPercent;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.createdById = createdById;
        this.updatedById = updatedById;

        this.guid = uuidv4();
    }
    static create() {
        return new ParentsEntity(0, '', '', '', '', '',
            '', '', '');
    }

    static createFromObject(parent) {
        return new ParentsEntity(parent.id, parent.name, parent.gender, parent.cropFamilyId, parent.germinationPercent,
            parent.createdAt, parent.updatedAt, parent.createdById, parent.updatedById);
    }
}
